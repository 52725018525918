import React, { Component } from "react";
import "./CoachBuilderQuiz.css";
import ConfettiGenerator from "confetti-js";
import bannerImg from "../lg.png";

const formatNumber = (num) => {
	return Math.round((num + Number.EPSILON) * 100) / 100;
};

const numberWithComma = (num) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class CoachBuilderQuiz extends Component {
	state = {
		name: "",
		email: "",
		phone: "",
		state: "",
		teamType: "",
		age: "",
		playerFee: "",
		playerCount: "",
		eventCost: 0,
		coachPay: 0,
		totalTravel: 0,
		totalOther: 0,
		paidUpfront: "",
		currentQuestion: 0,
		acceptedRecommended: false,
		accepted: false,
		suggestions: false,
		playerDefault: 500,
		questions: {
			generalInfo: "Coach, what is your general info?",
			// state: ", what state is your team in?",
			// email: ", what is your email address?",
			// phone:
			// 	", what is the phone number that you can recieve text messages at?",
			// teamType: "What kind of team are you forming?",
			// age: "Select Age Group:",
			teamInfo: "Please enter your team information",
			fees: "What are the estimated fees?",
			// 	playerFee: "What is your target player fee for each player on your team?",
			// playerCount: "How many paying players will you have?",
			// eventCost:
			// 	"What is your total event cost for the season, including tournaments and any other events?",
			// coachPay:
			// 	"How much would you like for ALL coach pay (includes head coach and assistants)",
			// travelExpenses:
			// 	"Do you have any travel expenses you would like to include in the team budget?",
			// totalTravel: "What is the total amount of travel expenses for the year?",
			// otherExpenses:
			// 	"Do you have any other expenses you would like to include in the team budget?",
			// totalOther:
			// 	"How much would you like to add for coach expenses (travel, hotel, etc):",
			paidUpfront:
				"At registration, US Elite will give parents the option to pay in full or pay monthly. If monthly is selected, US Elite will collect a downpayment and then set up monthly payments. The down payment amount impacts how much money we collect for your team upfront and how much the monthly payments will be for those who pay monthly. Select the percentage you would like us to collect upfront:",
		},
		changed: {
			name: false,
			state: false,
			email: false,
			phone: false,
			teamType: false,
			age: false,
			teamInfo: false,
			playerFee: false,
			playerCount: false,
			eventCost: false,
			coachPay: false,
			travelExpenses: false,
			totalTravel: false,
			otherExpenses: false,
			totalOther: false,
			paidUpfront: false,
		},
		error_name: false,
	};

	handleSubmit(e) {
		e.preventDefault();
		const {
			playerDefault,
			playerCount,
			eventCost,
			coachPay,
			totalTravel,
			totalOther,
		} = this.state;
		const totalExpenses =
			parseInt(eventCost) +
			parseInt(coachPay) +
			parseInt(totalTravel ? totalTravel : 0) +
			parseInt(totalOther ? totalOther : 0);
		const teamCost = totalExpenses + playerDefault * parseInt(playerCount);
		const otherFee = teamCost * 0.1;
		const totalTeamCost = teamCost + otherFee;
		const playerFee = formatNumber(totalTeamCost / parseInt(playerCount));

		// const totalRevenue = parseInt(playerFee) * parseInt(playerCount);
		// const accepted = totalRevenue * 0.45 >= totalExpenses;
		// const coachPayout =
		// 	totalExpenses <= totalRevenue * 0.45 && !this.state.acceptedRecommended
		// 		? totalExpenses
		// 		: totalRevenue * 0.45;

		const formData = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.phone,
			state: this.state.state,
			teamType: `${this.state.age}U ${
				this.state.teamType.charAt(0).toUpperCase() +
				this.state.teamType.slice(1)
			}`,
			playerCount: playerCount,
			playerFee: playerFee,
			eventCost: eventCost,
			coachPay: coachPay,
			totalTravel: totalTravel,
			totalOther: totalOther,
			paidUpfront: this.state.paidUpfront,
		};
		// if (accepted) {
		fetch("https://budget-creator-api.onrender.com/api/budget-creator/new", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: "budget-creator-api-token",
			},
			body: JSON.stringify(formData),
		}).then((res) => console.log(res));
		this.setState({
			accepted: true,
		});
		// } else {
		// 	this.setState({
		// 		suggestions: true,
		// 	});
		// }
	}

	handlePhone(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			phone: e.target.value,
			changed: {
				...this.state.changed,
				phone: true,
			},
		});
	}

	handleName(e) {
		// if (e.target.value.trim().length >= 1) {
		// 	document.getElementById("next_btn").classList.remove("disabled");
		// }
		this.setState({
			name: e.target.value,
			changed: {
				...this.state.changed,
				name: true,
			},
		});
	}

	handleState(e) {
		// if (e.target.value.trim().length >= 2) {
		// 	document.getElementById("next_btn").classList.remove("disabled");
		// }
		this.setState({
			state: e.target.value,
			changed: {
				...this.state.changed,
				state: true,
			},
		});
	}

	handleEmail(e) {
		//regex to check if it is an email syntax
		// if (
		// 	/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(e.target.value)
		// ) {
		// 	document.getElementById("next_btn").classList.remove("disabled");
		// } else {
		// 	document.getElementById("next_btn").classList.add("disabled");
		// }

		this.setState({
			email: e.target.value,
			changed: {
				...this.state.changed,
				email: true,
			},
		});
	}

	handleTeamType(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			teamType: e.target.value,
			changed: {
				...this.state.changed,
				teamType: true,
			},
		});
	}
	handleAge(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			age: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				age: true,
			},
		});
	}
	handlePlayerFee(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			playerFee: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				playerFee: true,
			},
		});
	}
	handlePlayerCount(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			playerCount: e.target.value,
			changed: {
				...this.state.changed,
				playerCount: true,
			},
		});
	}
	handleEventCost(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			eventCost: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				eventCost: true,
			},
		});
	}
	handleCoachPay(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			coachPay: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				coachPay: true,
			},
		});
	}
	handleTotalTravel(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			totalTravel: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				totalTravel: true,
			},
		});
	}
	handleSkipBtns(e, type) {
		e.preventDefault();
		if (type === "no") {
			this.setState({
				currentQuestion: this.state.currentQuestion + 2,
			});
		} else if (type === "yes") {
			this.setState({
				currentQuestion: this.state.currentQuestion + 1,
			});
		}
	}
	handleOtherTotal(e) {
		// document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			totalOther: e.target.value.replace(",", ""),
			changed: {
				...this.state.changed,
				totalOther: true,
			},
		});
	}
	handleUpfront(e) {
		e.preventDefault();
		document.getElementById("submit_btn").classList.remove("disabled");
		const prevActive = document.querySelector(".percent-active");
		if (prevActive) prevActive.classList.remove("percent-active");
		e.target.classList.add("percent-active");
		this.setState({
			paidUpfront: e.target.innerText,
			changed: {
				...this.state.changed,
				paidUpfront: true,
			},
		});
	}
	// handleOptionOne(e, totalRevenue, totalExpenses) {
	// 	e.preventDefault();
	// 	const { playerCount, eventCost, coachPay, totalTravel, totalOther } =
	// 		this.state;
	// 	const expenses =
	// 		parseInt(eventCost) +
	// 		parseInt(coachPay) +
	// 		parseInt(totalTravel ? totalTravel : 0) +
	// 		parseInt(totalOther ? totalOther : 0);
	// 	this.setState({
	// 		playerFee:
	// 			parseInt(this.state.playerFee) +
	// 			Math.ceil(
	// 				totalExpenses / 0.45 / parseInt(this.state.playerCount) -
	// 					parseInt(this.state.playerFee)
	// 			),
	// 	});

	// 	const revenue =
	// 		parseInt(this.state.playerCount) *
	// 		(parseInt(this.state.playerFee) +
	// 			Math.ceil(
	// 				totalExpenses / 0.45 / parseInt(playerCount) -
	// 					parseInt(this.state.playerFee)
	// 			));

	// 	const accepted = revenue * 0.45 >= expenses;
	// 	const formData = {
	// 		name: this.state.name,
	// 		email: this.state.email,
	// 		phone: this.state.phone,
	// 		state: this.state.state,
	// 		teamType: `${this.state.age}U ${
	// 			this.state.teamType.charAt(0).toUpperCase() +
	// 			this.state.teamType.slice(1)
	// 		}`,
	// 		playerCount: this.state.playerCount,
	// 		playerFee:
	// 			parseInt(this.state.playerFee) +
	// 			Math.ceil(
	// 				totalExpenses / 0.45 / parseInt(this.state.playerCount) -
	// 					parseInt(this.state.playerFee)
	// 			),
	// 		eventCost: this.state.eventCost,
	// 		coachPay: this.state.coachPay,
	// 		totalTravel: this.state.totalTravel,
	// 		totalOther: this.state.totalOther,
	// 		paidUpfront: this.state.paidUpfront,
	// 		coachPayout: this.state.coachPayout,
	// 	};
	// 	if (accepted) {
	// 		fetch("https://budget-creator.herokuapp.com/api/budget-creator/new", {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				authorization: "budget-creator-api-token",
	// 			},
	// 			body: JSON.stringify(formData),
	// 		}).then((res) => console.log(res));

	// 		this.setState({
	// 			accepted: true,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			suggestions: true,
	// 		});
	// 	}
	// }
	// handleOptionTwo(e, totalRevenue, totalExpenses) {
	// 	e.preventDefault();
	// 	const { eventCost, coachPay, totalTravel, totalOther } = this.state;
	// 	const expenses =
	// 		parseInt(eventCost) +
	// 		parseInt(coachPay) +
	// 		parseInt(totalTravel ? totalTravel : 0) +
	// 		parseInt(totalOther ? totalOther : 0);

	// 	this.setState({
	// 		playerCount:
	// 			parseInt(this.state.playerCount) +
	// 			Math.ceil(
	// 				totalExpenses / 0.45 / parseInt(this.state.playerFee) -
	// 					this.state.playerCount
	// 			),
	// 	});
	// 	const revenue =
	// 		parseInt(this.state.playerFee) *
	// 			parseInt(
	// 				Math.ceil(
	// 					totalExpenses / 0.45 / parseInt(this.state.playerFee) -
	// 						this.state.playerCount
	// 				)
	// 			) +
	// 		this.state.playerCount;
	// 	const accepted = revenue * 0.45 >= expenses;
	// 	const formData = {
	// 		name: this.state.name,
	// 		email: this.state.email,
	// 		phone: this.state.phone,
	// 		state: this.state.state,
	// 		teamType: `${this.state.age}U ${
	// 			this.state.teamType.charAt(0).toUpperCase() +
	// 			this.state.teamType.slice(1)
	// 		}`,
	// 		playerCount:
	// 			parseInt(this.state.playerCount) +
	// 			Math.ceil(
	// 				totalExpenses / 0.45 / parseInt(this.state.playerFee) -
	// 					this.state.playerCount
	// 			),
	// 		playerFee: this.state.playerFee,
	// 		eventCost: this.state.eventCost,
	// 		coachPay: this.state.coachPay,
	// 		totalTravel: this.state.totalTravel,
	// 		totalOther: this.state.totalOther,
	// 		paidUpfront: this.state.paidUpfront,
	// 		coachPayout: this.state.coachPayout,
	// 	};
	// 	if (accepted) {
	// 		fetch("https://budget-creator.herokuapp.com/api/budget-creator/new", {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				authorization: "budget-creator-api-token",
	// 			},
	// 			body: JSON.stringify(formData),
	// 		}).then((res) => console.log(res));

	// 		this.setState({
	// 			accepted: true,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			suggestions: true,
	// 		});
	// 	}
	// }
	// handleOptionThree(e, totalRevenue, totalExpenses) {
	// 	e.preventDefault();
	// 	const { playerFee, playerCount, coachPay, totalTravel, totalOther } =
	// 		this.state;
	// 	const amtToReduce = totalExpenses - totalRevenue * 0.45;
	// 	const expenses =
	// 		parseInt(this.state.eventCost - amtToReduce) +
	// 		parseInt(coachPay) +
	// 		parseInt(totalTravel ? totalTravel : 0) +
	// 		parseInt(totalOther ? totalOther : 0);
	// 	const revenue = parseInt(playerFee) * parseInt(playerCount);
	// 	this.setState({
	// 		eventCost: parseInt(this.state.eventCost) - amtToReduce,
	// 	});
	// 	const accepted = revenue * 0.45 >= expenses;
	// 	const formData = {
	// 		name: this.state.name,
	// 		email: this.state.email,
	// 		phone: this.state.phone,
	// 		state: this.state.state,
	// 		teamType: `${this.state.age}U ${
	// 			this.state.teamType.charAt(0).toUpperCase() +
	// 			this.state.teamType.slice(1)
	// 		}`,
	// 		playerCount: this.state.playerCount,
	// 		playerFee: this.state.playerFee,
	// 		eventCost: parseInt(this.state.eventCost) - amtToReduce,
	// 		coachPay: this.state.coachPay,
	// 		totalTravel: this.state.totalTravel,
	// 		totalOther: this.state.totalOther,
	// 		paidUpfront: this.state.paidUpfront,
	// 		coachPayout: this.state.coachPayout,
	// 	};
	// 	if (accepted) {
	// 		fetch("https://budget-creator.herokuapp.com/api/budget-creator/new", {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				authorization: "budget-creator-api-token",
	// 			},
	// 			body: JSON.stringify(formData),
	// 		}).then((res) => console.log(res));

	// 		this.setState({
	// 			accepted: true,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			suggestions: true,
	// 		});
	// 	}
	// }
	handleStartOver(e) {
		e.preventDefault();
		this.setState({
			currentQuestion: 0,
			accepted: false,
			suggestions: false,
		});
	}
	// handleAcceptRecommended(e, amt) {
	// 	e.preventDefault();
	// 	const lastQuestion = Object.keys(this.state.questions).length;
	// 	this.setState({
	// 		playerFee: amt.fmf,
	// 		coachPay: amt.total * 0.1,
	// 		playerCount: amt.players,
	// 		currentQuestion: lastQuestion - 1,
	// 		acceptedRecommended: true,
	// 	});
	// }

	handleNext(e) {
		e.preventDefault();
		const shouldBeDisabled = Object.keys(this.state.changed)[
			this.state.currentQuestion
		]
			? this.state.changed[
					Object.keys(this.state.changed)[this.state.currentQuestion]
			  ]
			: false;
		if (document.getElementById("next_btn").classList.contains("disabled"))
			return;

		if (!shouldBeDisabled)
			document.getElementById("next_btn").classList.add("disabled");
		this.setState({
			currentQuestion: this.state.currentQuestion + 1,
		});
	}
	handleBack(e) {
		e.preventDefault();
		if (document.getElementById("next_btn"))
			document.getElementById("next_btn").classList.remove("disabled");
		this.setState({
			currentQuestion: this.state.currentQuestion - 1,
		});
	}

	renderQuestions() {
		const questions = Object.values(this.state.questions);
		const index = this.state.currentQuestion;
		return (
			<h2 className="NewApptForm__question" key={this.state.currentQuestion}>
				{questions[index]}
			</h2>
		);
	}

	renderButtons() {
		const currentQuestion = Object.keys(this.state.questions)[
			this.state.currentQuestion
		];
		const nextQuestion = Object.keys(this.state.questions)[
			this.state.currentQuestion + 1
		];
		const questions = {
			generalInfo:
				this.state.changed.name &&
				this.state.changed.email &&
				this.state.changed.phone &&
				this.state.changed.state,
			teamInfo:
				this.state.changed.playerCount &&
				this.state.changed.age &&
				this.state.changed.teamType,
			fees: true,
		};
		const shouldNotBeDisabled = questions[currentQuestion];
		const nextBtnClass = `NewApptForm__btn ${
			shouldNotBeDisabled ? "" : "disabled"
		}`;

		const backButton =
			this.state.currentQuestion !== 0 ? (
				<div
					className="NewApptForm__btn back"
					onClick={(e) => this.handleBack(e)}
				>
					Back
				</div>
			) : (
				<React.Fragment />
			);
		return nextQuestion !== undefined ? (
			<div className="NewApptForm__buttons">
				{backButton}
				{Object.keys(this.state.questions)[this.state.currentQuestion] !==
					"travelExpenses" &&
				Object.keys(this.state.questions)[this.state.currentQuestion] !==
					"otherExpenses" ? (
					<button
						className={nextBtnClass}
						id="next_btn"
						onClick={(e) => this.handleNext(e)}
					>
						Next
					</button>
				) : (
					<></>
				)}
			</div>
		) : (
			<div>
				{backButton}
				<button
					className="NewApptForm__btn disabled"
					id="submit_btn"
					type="submit"
				>
					Submit
				</button>
			</div>
		);
	}
	renderInputs() {
		const currentIndex = Object.keys(this.state.questions)[
			this.state.currentQuestion
		];
		if (currentIndex === "generalInfo") {
			return (
				<div>
					<div className="NewApptForm__section">
						<label for="schedule_name">Your Name:</label>
						<input
							type="text"
							id="schedule_name"
							name="schedule_name"
							className="NewApptForm__input"
							value={this.state.name}
							onChange={(e) => this.handleName(e)}
							required
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="schedule_email">Email:</label>
						<input
							type="text"
							id="schedule_email"
							className="NewApptForm__input"
							value={this.state.email}
							onChange={(e) => this.handleEmail(e)}
							name="schedule_email"
							required
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="phone">Phone Number:</label>
						<input
							type="tel"
							id="phone"
							name="phone"
							className="NewApptForm__input"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							value={this.state.phone}
							onChange={(e) => this.handlePhone(e)}
							required
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="schedule_state">State:</label>
						<input
							type="text"
							name="schedule_state"
							className="NewApptForm__input"
							value={this.state.state}
							onChange={(e) => {
								this.handleState(e);
							}}
							required
						/>
					</div>

					{/* <div className="NewApptForm__section">
						<select
							onChange={(e) => this.handleTeamType(e)}
							defaultValue="default"
							style={{ width: "80%" }}
						>
							<option value="default" hidden>
								Select Team Type
							</option>
							<option value="local">
								Local: Team plays mostly local tournaments. Most players are
								from one hour radius.
							</option>
							<option value="state">
								State: Team plays in events around the state and region. Players
								are from throughout the state.
							</option>
							<option value="regional">
								Regional: Team plays in state, regional and national events.
								Players live in multiple states. Level of play is at the top end
								of competition level.
							</option>
							<option value="platinum">
								Platinum: Players are from anywhere and will play everywhere
								that the best tournaments are!
							</option>
						</select>
					</div>

					<div className="NewApptForm__section">
						<select onChange={(e) => this.handleAge(e)} defaultValue="default">
							<option value="default" hidden>
								Select Age
							</option>
							<option value="6">6U</option>
							<option value="7">7U</option>
							<option value="8">8U</option>
							<option value="9">9U</option>
							<option value="10">10U</option>
							<option value="11">11U</option>
							<option value="12">12U</option>
							<option value="13">13U</option>
							<option value="14">14U</option>
							<option value="15">15U</option>
							<option value="16">16U</option>
							<option value="17">17U</option>
							<option value="18">18U</option>
						</select>
					</div> */}

					{this.state.error_name ? (
						<p>Please Complete Required Fields</p>
					) : (
						<React.Fragment />
					)}
				</div>
			);
		} else if (currentIndex === "teamInfo") {
			// const { age, teamType } = this.state;
			// const amt = recommended[age + "U"][teamType];
			return (
				<>
					<div className="NewApptForm__section">
						<label for="schedule_players">Number of players:</label>
						<input
							type="number"
							id="players"
							name="schedule_players"
							className="NewApptForm__input"
							value={this.state.playerCount}
							min={10}
							pattern="[0-9]{3}"
							onChange={(e) => this.handlePlayerCount(e)}
							required
						/>
					</div>
					<div className="NewApptForm__section">
						<select
							onChange={(e) => this.handleTeamType(e)}
							defaultValue="default"
							style={{ width: "80%" }}
						>
							<option value="default" hidden>
								Select Team Type
							</option>
							<option value="local">
								Local: Most players are from one hour radius.
							</option>
							<option value="state">
								State: Players are from throughout the state.
							</option>
							<option value="regional">
								Regional: Players live in multiple states. Top end of
								competition level.
							</option>
							<option value="platinum">
								Platinum: Players are from anywhere and will play where the best
								tournaments are!
							</option>
						</select>
					</div>

					<div className="NewApptForm__section">
						<select onChange={(e) => this.handleAge(e)} defaultValue="default">
							<option value="default" hidden>
								Select Age
							</option>
							<option value="6">6U</option>
							<option value="7">7U</option>
							<option value="8">8U</option>
							<option value="9">9U</option>
							<option value="10">10U</option>
							<option value="11">11U</option>
							<option value="12">12U</option>
							<option value="13">13U</option>
							<option value="14">14U</option>
							<option value="15">15U</option>
							<option value="16">16U</option>
							<option value="17">17U</option>
							<option value="18">18U</option>
						</select>
					</div>
				</>
			);
		} else if (currentIndex === "fees") {
			return (
				<div>
					<div className="NewApptForm__section">
						<label for="fees_event-cost">Event Cost: </label>${" "}
						<input
							type="number"
							name="fees_event-cost"
							value={this.state.eventCost}
							onChange={(e) => this.handleEventCost(e)}
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="fees_coach-pay">Coach Pay: </label>${" "}
						<input
							type="number"
							name="fees_coach-pay"
							value={this.state.coachPay}
							onChange={(e) => this.handleCoachPay(e)}
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="fees_total-travel">Total Travel: </label>${" "}
						<input
							type="number"
							name="fees_total-travel"
							value={this.state.totalTravel}
							onChange={(e) => this.handleTotalTravel(e)}
						/>
					</div>

					<div className="NewApptForm__section">
						<label for="fees_other">Other Fees: </label>${" "}
						<input
							type="number"
							name="fees_other"
							value={this.state.totalOther}
							onChange={(e) => this.handleOtherTotal(e)}
						/>
					</div>
				</div>
			);
		} else if (currentIndex === "paidUpfront") {
			return (
				<div className="NewApptForm__section">
					<div className="percent-buttons-container">
						<button
							className="percent-buttons"
							onClick={(e) => this.handleUpfront(e)}
						>
							25%
						</button>
						<button
							className="percent-buttons"
							onClick={(e) => this.handleUpfront(e)}
						>
							50%
						</button>
						<button
							className="percent-buttons"
							onClick={(e) => this.handleUpfront(e)}
						>
							75%
						</button>
						<button
							className="percent-buttons"
							onClick={(e) => this.handleUpfront(e)}
						>
							100%
						</button>
					</div>
				</div>
			);
		}
	}

	renderSuccess() {
		const confettiElement = document.getElementById("my-canvas");

		let confettiSettings = {
			target: confettiElement,
			respawn: false,
			size: 2,
			clock: 15,
		};
		let confetti = new ConfettiGenerator(confettiSettings);
		confetti.render();
		const {
			eventCost,
			coachPay,
			totalOther,
			totalTravel,
			playerDefault,
			playerCount,
		} = this.state;
		const totalExpenses =
			parseInt(eventCost) +
			parseInt(coachPay) +
			parseInt(totalTravel ? totalTravel : 0) +
			parseInt(totalOther ? totalOther : 0);
		const teamCost = totalExpenses + playerDefault * parseInt(playerCount);
		const otherFee = teamCost * 0.1;
		const totalTeamCost = teamCost + otherFee;
		const playerFee = formatNumber(totalTeamCost / parseInt(playerCount));
		const fairMarketValue = formatNumber(playerFee * 0.2);
		return (
			<div className="NewApptForm__form">
				<div className="NewApptForm__section">
					<h1>
						Congratulations Coach {this.state.name}, your budget has been
						approved!
					</h1>
					<br />
					<p>Per Player Fee: ${numberWithComma(playerFee)}</p>
					<p>Total Paying Players: {this.state.playerCount}</p>
					<p>
						Total Coaches allowance: $
						{numberWithComma(
							parseInt(this.state.eventCost) +
								parseInt(this.state.coachPay) +
								parseInt(this.state.totalTravel ? this.state.totalTravel : 0) +
								parseInt(this.state.totalOther ? this.state.totalOther : 0)
						)}
					</p>
					<br />
					<p>
						Fair market value for this team is $
						{numberWithComma(playerFee + fairMarketValue)} a player
					</p>
					<br />
					<p>
						Savings under US Elite: $
						{numberWithComma(playerFee + fairMarketValue)} - $
						{numberWithComma(playerFee)} = ${numberWithComma(fairMarketValue)}
					</p>
					<br />
					<p>All expenses are approved.</p>
					<p>
						We will send you an email with instructions for setting up your team
						and inviting players.
					</p>

					<div className="NewApptForm__buttons">
						<button
							className="NewApptForm__btn"
							onClick={(e) => this.handleStartOver(e)}
						>
							Restart Budget Creator?
						</button>
					</div>
				</div>
			</div>
		);
	}

	// renderSuggestions() {
	// 	const {
	// 		playerFee,
	// 		playerCount,
	// 		eventCost,
	// 		coachPay,
	// 		totalTravel,
	// 		totalOther,
	// 	} = this.state;
	// 	// const totalExpenses =
	// 	// 	parseInt(eventCost) +
	// 	// 	parseInt(coachPay) +
	// 	// 	parseInt(totalTravel ? totalTravel : 0) +
	// 	// 	parseInt(totalOther ? totalOther : 0);
	// 	const totalExpenses =
	// 		600 * parseInt(playerCount) +
	// 		(parseInt(eventCost) +
	// 			parseInt(coachPay) +
	// 			parseInt(totalTravel) +
	// 			parseInt(totalOther)) /
	// 			parseInt(playerCount);
	// 	const totalRevenue = parseInt(playerFee) * parseInt(playerCount);
	// 	return (
	// 		<div className="NewApptForm__form">
	// 			<div className="NewApptForm__section">
	// 				<h1>Sorry Coach, your budget is not approved yet.</h1>
	// 				<br />
	// 				<p>
	// 					The desired player fees aren't quite enough to cover your expenses.
	// 				</p>
	// 				<div className="suggested-options">
	// 					<p>
	// 						<strong>Option 1:</strong> Increase player fees by $
	// 						{Math.ceil(
	// 							totalExpenses / 0.45 / parseInt(playerCount) -
	// 								parseInt(playerFee)
	// 						)}
	// 					</p>
	// 					<button
	// 						onClick={(e) =>
	// 							this.handleOptionOne(e, totalRevenue, totalExpenses)
	// 						}
	// 					>
	// 						Accept Option
	// 					</button>
	// 				</div>
	// 				<div className="suggested-options">
	// 					<p>
	// 						<strong>Option 2:</strong> Increase number of paying players by{" "}
	// 						{Math.ceil(
	// 							totalExpenses / 0.45 / parseInt(playerFee) - playerCount
	// 						)}
	// 					</p>
	// 					<button
	// 						onClick={(e) =>
	// 							this.handleOptionTwo(e, totalRevenue, totalExpenses)
	// 						}
	// 					>
	// 						Accept Option
	// 					</button>
	// 				</div>
	// 				<div className="suggested-options">
	// 					<p>
	// 						<strong>Option 3:</strong> Reduce total expenses by $
	// 						{totalExpenses - totalRevenue * 0.45}
	// 					</p>
	// 					<button
	// 						onClick={(e) =>
	// 							this.handleOptionThree(e, totalRevenue, totalExpenses)
	// 						}
	// 					>
	// 						Accept Option
	// 					</button>
	// 				</div>
	// 				<br />
	// 				<div className="suggested-options">
	// 					<button onClick={(e) => this.handleStartOver(e)}>
	// 						Restart Budget Creator
	// 					</button>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// }

	renderPage() {
		if (this.state.accepted) {
			return this.renderSuccess();
		} else if (this.state.suggestions) {
			return this.renderSuggestions();
		} else {
			return (
				<form
					id="new-appointment"
					onSubmit={(e) => this.handleSubmit(e)}
					method="post"
					action="https://uselitebaseball.com/creator/process"
					className="NewApptForm__form"
				>
					{this.renderQuestions()}
					{this.renderInputs()}
					{Object.keys(this.state.questions)[this.state.currentQuestion] !==
					"recommends" ? (
						this.renderButtons()
					) : (
						<></>
					)}
				</form>
			);
		}
	}
	render() {
		return (
			<div className="NewApptForm">
				<div className="img-container">
					<img className="header-img" src={bannerImg} alt="US Elite Logo" />
				</div>
				<main className="NewApptForm__main">{this.renderPage()}</main>
			</div>
		);
	}
}

export default CoachBuilderQuiz;

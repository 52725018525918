import React, { Component } from "react";
import "./CoachBuilderQuiz.css";
import { Link } from "react-router-dom";
import bannerImg from "../lg.png";

class LandingPage extends Component {
	render() {
		return (
			<div>
				<div className="img-container">
					<img className="header-img" src={bannerImg} alt="US Elite Logo" />
				</div>
				<div className="landing-page">
					<h1>Introducing the all new Budget Creator!</h1>
					<br />
					<p>
						US Elite is the first (and only) national program in the country to
						allow it’s coaches to create their own budgets. That’s UNCOMMON!
					</p>
					<br />
					<div className="link-container">
						<Link to="/creator" className="link">
							Calculate your team’s player fees.
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
export default LandingPage;
